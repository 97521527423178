// import jwt from "jsonwebtoken";
import router from "@/router";
import auth from "@/store";
export default [
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/admin/layouts/Admin.vue"),
    beforeEnter: (to, from, next) => {
      // const user = jwt.decode(localStorage.getItem("token"));
      if (["admin"].includes(auth.state.auth.user.role)) {
        next();
      } else {
        router.push({ name: "Home" });
        next(false);
      }
    },
    children: [
      {
        path: "categories",
        name: "categories",
        component: () => import("@/admin/pages/categories/Category.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },

      {
        path: "add-category",
        name: "add-category",
        component: () => import("@/admin/pages/categories/AddCategory.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "view-order",
        name: "view-order",
        component: () => import("@/admin/pages/product/Vieworder.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "Edit-category",
        name: "edit-category",
        component: () => import("@/admin/pages/categories/EditCategory.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      //  subcategory
      {
        path: "Sub-category",
        name: "sub-categories",
        component: () =>
          import("@/admin/pages/sub-categories/Sub-Category.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-Sub-category",
        name: "add-subcategory",
        component: () =>
          import("@/admin/pages/sub-categories/Addsubcategory.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "edit-Sub-category",
        name: "edit-subcategory",
        component: () =>
          import("@/admin/pages/sub-categories/EditsubCategory.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      // addproduct
      {
        path: "Add-Product",
        name: "add-Product",
        component: () =>
          import("@/admin/pages/add-product/AdminAdd-product.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "Add-new-Product",
        name: "add-new-Product",
        component: () => import("@/admin/pages/add-product/Add-Product.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      // pages-home -banner
      {
        path: "Banner",
        name: "banner",
        component: () => import("@/admin/pages/banner/Banner.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "tags",
        name: "tags",
        component: () => import("@/admin/pages/add-tags/Tags.vue"),
      },
      {
        path: "add-tags",
        name: "add-tags",
        component: () =>
          import("@/admin/pages/add-tags/components/AddTags.vue"),
      },
      {
        path: "edit-tags",
        name: "edit-tags",
        component: () =>
          import("@/admin/pages/add-tags/components/EditTags.vue"),
      },
      {
        path: "ad-blogs",
        name: "ad-blogs",
        component: () => import("@/admin/pages/ad-blogs/BlogsMain.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "addpost",
        name: "addpost",
        component: () => import("@/admin/pages/addpost/AddPost.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "posttable",
        name: "posttable",
        component: () =>
          import("@/admin/pages/addpost/components/PostTable.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "editpost",
        name: "editpost",
        component: () =>
          import("@/admin/pages/addpost/components/Edit_Post.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "AddBlogs",
        name: "AddBlogs",
        component: () => import("@/admin/pages/ad-blogs/AddBlogs.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "EditBlogs",
        name: "EditBlogs",
        component: () => import("@/admin/pages/ad-blogs/EditBlogs.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "PodcastNext",
        name: "PodcastNext",
        component: () => import("@/admin/pages/add-podcast/PodcastNext.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-podcast",
        name: "add-podcast",
        component: () => import("@/admin/pages/add-podcast/AddPodcast.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "Edit-Banner",
        name: "edit-banner",
        component: () => import("@/admin/pages/banner/Edit-Banner.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "Add-Banner",
        name: "add-banner",
        component: () => import("@/admin/pages/banner/Add-Banner.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "SEO",
        name: "SEO",
        component: () => import("@/admin/pages/seo/Seo.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "admin-testimonail",
        name: "admin-testimonail",
        component: () =>
          import("@/admin/pages/hometestimonial/AdminTestimonail.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "Collection-Seo",
        name: "Collection-Seo",
        component: () => import("@/admin/pages/seo/CollectionSeo.vue"),
      },
      {
        path: "Product-Seo",
        name: "Product-Seo",
        component: () => import("@/admin/pages/seo/ProductSeo.vue"),
      },
      {
        path: "Community-Seo",
        name: "Community-Seo",
        component: () => import("@/admin/pages/seo/CommunitySeo.vue"),
      },
      {
        path: "Connect-Seo",
        name: "Connect-Seo",
        component: () => import("@/admin/pages/seo/ConnectSeo.vue"),
      },
      {
        path: "HRXHub-Seo",
        name: "HRXHub-Seo",
        component: () => import("@/admin/pages/seo/HRXhubSeo.vue"),
      },
      {
        path: "PressRelease-Seo",
        name: "PressRelease-Seo",
        component: () => import("@/admin/pages/seo/PressReleaseSeo.vue"),
      },
      {
        path: "BlogMainPageSEO",
        name: "BlogMainPageSEO",
        component: () => import("@/admin/pages/seo/BlogMainPageSEO.vue"),
      },
      {
        path: "ManageEvent",
        name: "ManageEvent",
        component: () => import("@/admin/pages/event/EventMain.vue"),
      },
      {
        path: "Add-Event",
        name: "Add-Event",
        component: () => import("@/admin/pages/event/AddEvent.vue"),
      },
      {
        path: "Edit-Event",
        name: "Edit-Event",
        component: () => import("@/admin/pages/event/EditEvent.vue"),
      },
      {
        path: "Main-EventTable",
        name: "Main-EventTable",
        component: () =>
          import("@/admin/pages/event/components/MainEventTable.vue"),
      },

      {
        path: "MainBooking-EventTable",
        name: "MainBooking-EventTable",
        component: () =>
          import(
            "@/admin/pages/eventBooking/components/MainBookingEventTable.vue"
          ),
      },
      {
        path: "BookingTable",
        name: "BookingTable",
        component: () => import("@/admin/pages/eventBooking/BookingTable.vue"),
      },
      {
        path: "Main-ProductTable",
        name: "Main-ProductTable",
        component: () =>
          import("@/admin/pages/product/components/MainProductTable.vue"),
      },
      {
        path: "ProductMain",
        name: "ProductMain",
        component: () => import("@/admin/pages/product/ProductMain.vue"),
      },
      {
        path: "AddProduct",
        name: "AddProduct",
        component: () => import("@/admin/pages/product/AddProduct.vue"),
      },
      {
        path: "AddCategory",
        name: "AddCategory",
        component: () => import("@/admin/pages/product/AddCategory.vue"),
      },
      {
        path: "AddSubCategory",
        name: "AddSubCategory",
        component: () => import("@/admin/pages/product/AddSubCategory.vue"),
      },
      {
        path: "SubCateProductTable",
        name: "SubCateProductTable",
        component: () =>
          import("@/admin/pages/product/components/SubCateProductTable.vue"),
      },
      {
        path: "ProductsTable",
        name: "ProductsTable",
        component: () =>
          import("@/admin/pages/product/components/ProductsTable.vue"),
      },
      {
        path: "EditProduct",
        name: "EditProduct",
        component: () => import("@/admin/pages/product/EditProduct.vue"),
      },
      {
        path: "UserMain",
        name: "UserMain",
        component: () => import("@/admin/pages/ManageUser/UserMain.vue"),
      },
      {
        path: "EventMain",
        name: "EventMain",
        component: () => import("@/admin/pages/eventBooking/EventMain.vue"),
      },
      {
        path: "ManageUserTable",
        name: "ManageUserTable",
        component: () =>
          import("@/admin/pages/ManageUser/components/ManageUserTable.vue"),
      },
      {
        path: "EditUser",
        name: "EditUser",
        component: () => import("@/admin/pages/ManageUser/Edit-User.vue"),
      },
      {
        path: "ManageProductSEO",
        name: "ManageProductSEO",
        component: () => import("@/admin/pages/product/ManageProductSEO.vue"),
      },
      {
        path: "ManageCategorySEO",
        name: "ManageCategorySEO",
        component: () => import("@/admin/pages/product/ManageCategorySEO.vue"),
      },
      {
        path: "ManageSubcategorySEO",
        name: "ManageSubcategorySEO",
        component: () =>
          import("@/admin/pages/product/ManageSubcategorySEO.vue"),
      },
      {
        path: "ManageBlogSEO",
        name: "ManageBlogSEO",
        component: () => import("@/admin/pages/ad-blogs/ManageBlogSEO.vue"),
      },
      {
        path: "EditCategory",
        name: "EditCategory",
        component: () => import("@/admin/pages/product/EditCategory.vue"),
      },
      {
        path: "EditSubcategory",
        name: "EditSubcategory",
        component: () => import("@/admin/pages/product/EditSubcategory.vue"),
      },
      {
        path: "ProductSubCateTable",
        name: "ProductSubCateTable",
        component: () =>
          import("@/admin/pages/product/components/ProductSubCateTable.vue"),
      },
      {
        path: "AddProductSubCategory",
        name: "AddProductSubCategory",
        component: () =>
          import("@/admin/pages/product/AddProductSubCategory.vue"),
      },
      {
        path: "EditProductSubcategory",
        name: "EditProductSubcategory",
        component: () =>
          import("@/admin/pages/product/EditProductSubcategory.vue"),
      },
      {
        path: "AddCatProduct",
        name: "AddCatProduct",
        component: () => import("@/admin/pages/product/AddCatProduct.vue"),
      },
      {
        path: "admin-press-release",
        name: "PressRelease",
        component: () =>
          import("@/admin/pages/pressrelease/PressReleaseAdmin.vue"),
      },
      {
        path: "add-press-release",
        name: "add-press-release",
        component: () =>
          import("@/admin/pages/pressrelease/components/AddPressRelease.vue"),
      },
      {
        path: "edit-press-release",
        name: "edit-press-release",
        component: () =>
          import("@/admin/pages/pressrelease/components/EditPressRelease.vue"),
      },
    ],
    meta: {
      rule: "editor",
      authRequired: true,
    },
  },
];
