import RepositoryFactory from "@/apis";
const category = RepositoryFactory.category;

export default {
  AddCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await category.AddCategory(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  UpdateCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await category.UpdateCategory(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await category.GetCategory(payload);

        commit("SET_CATEGORY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetAllCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await category.GetAllCategory(payload);

        commit("SET_ALLCATEGORY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  DeleteCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await category.DeleteCategory(payload);

        commit("DELETE_CATEGORY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
