export default {
  page: [],
  getpagesbyid: [],
  getallpagesbyid: [],
  getpodcastbyid: [],
  getsinglepage: {},
  addpage: [],
  insertpages: {},
  previousBlog: [],
  tagid: "",
  page_details: {},
  page_bytagid: {},
};
