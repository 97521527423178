import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const CREATE_TESTIMONIAL = "create";
const GET_TESTIMONIAL = "get";
const UPDATE_TESTIMONIAL = "Update";
const DELETE_TESTIMONIAL = "delete";

export default {
  CreateTestimonial(payload) {
    return Repository.post(
      transformRoute(CREATE_TESTIMONIAL),
      payload,
      formDataConfig
    );
  },
  GetTestimonial(payload) {
    return Repository.post(transformRoute(GET_TESTIMONIAL), payload);
  },

  UpdateTestimonial(payload) {
    return Repository.post(
      transformRoute(UPDATE_TESTIMONIAL),
      payload,
      formDataConfig
    );
  },
  DeleteTestimonial(payload) {
    return Repository.post(transformRoute(DELETE_TESTIMONIAL), payload);
  },
};

const transformRoute = (route) => {
  return `/testimonial/${route}`;
};
