import post from "@/apis/post";
import Vue from "vue";
export default {
  RESET_CREATE_POST(state) {
    state.type = "";
    state.media = null;
    state.text = "";
    state.url = "";
    state.Answers = "";
    state.selectedChannel = null;
  },
  SET_ALL_POSTS(state, payload) {
    // state.posts.unshift(payload);
    state.posts = payload;
  },
  ADD_NEW_POST(state, payload) {
    state.posts.unshift(payload);
  },
  SET_POST(state, payload) {
    state[payload.key] = payload.value;
  },
  SET_POSTING_CHANNELS(state, payload) {
    state.postingChannels = payload;
  },
  SET_CURRENT_PAGE(state, payload) {
    state.previousPage = state.currentPage;
    state.currentPage = payload;
  },
  LOAD_POST_COMMENT(state, payload) {
    if (state.postComments[payload._id]) {
      Vue.set(state.postComments[payload._id], payload._id, payload.comments);
    } else {
      Vue.set(state.postComments, payload._id, payload.comments);
    }
  },
  LOAD_COMMENT_REPLY(state, payload) {
    state.commentReplies[payload._id] = payload.replies;
  },
  ADD_POST_COMMENT(state, payload) {
    if (state.postComments[payload.parentId]) {
      // let newPayload;
      // if (payload._user._id) {
      //   newPayload = {
      //     ...payload,
      //     _user: { ...payload._user, id: payload._user._id },
      //   };
      // } else {
      //   newPayload = payload;
      // }
      Vue.set(state.postComments, payload.parentId, [
        payload,
        ...state.postComments[payload.parentId],
      ]);
      let postIndex = state.posts.findIndex(
        (post) => post._id == payload.parentId
      );
      state.selectedPost.comments.push({ ...payload, isReplyOpen: false });
      state.posts[postIndex].comments.push(payload);
      Vue.set(
        state.posts[postIndex],
        "totalComments",
        state.posts[postIndex].totalComments + 1
      );
      // Vue.set(state.currentuserpostComments, payload.parentId, [
      //   ...state.currentuserpostComments[payload.parentId],
      //   newPayload,
      // ]);
    }
  },
  ADD_COMMENT_REPLY(state, payload) {
    // let newPayload;
    // if (payload._user._id) {
    //   newPayload = {
    //     ...payload,
    //     _user: { ...payload._user, id: payload._user._id },
    //   };
    // } else {
    //   newPayload = payload;
    // }
    // if (state.commentReplies[payload.parentId]) {
    //   Vue.set(state.commentReplies, payload.parentId, [
    //     payload,
    //     ...state.commentReplies[payload.parentId],
    //   ]);

    //   if (state.currentusercommentReplies[payload.parentId]) {
    //     Vue.set(state.currentusercommentReplies, payload.parentId, [
    //       payload,
    //       ...state.currentusercommentReplies[payload.parentId],
    //     ]);
    //   }
    // } else {
    //   state.commentReplies[payload.parentId] = [payload];
    //   state.currentusercommentReplies[payload.parentId] = [payload];
    // }
    let postIndex = state.posts.findIndex(
      (x) => x._id == state.selectedPost._id
    );
    let commentIndex = state.posts[postIndex].comments.findIndex(
      (x) => x._id == payload.parentId
    );
    state.posts[postIndex].comments[commentIndex].replies.push(payload);
  },
  COUNT_INCREMENT_COMMENT(state, payload) {
    const commentIndex = state.postComments[payload.postId].findIndex(
      (x) => x._id === payload.commentId
    );
    state.postComments[payload.postId][commentIndex].totalReplies += 1;
    // state.selectedPost.comments[commentIndex].totalReplies += 1;
  },
  LIKE_POST(state, payload) {
    state.posts = state.posts.map((post) => {
      if (post._id == payload._id) {
        return {
          ...post,
          totalLikes: payload.totalLikes,
          like: payload.totalLikes,
          isLiked: payload.isLiked,
        };
      }
      return post;
    });

    state.currentuserpost = state.currentuserpost.map((post) => {
      console.log(post, post._id == payload._id);
      if (post._id == payload._id) {
        return payload;
      }
      return post;
    });
  },
  UNLIKE_POST(state, payload) {
    state.posts = state.posts.map((post) => {
      if (post._id == payload._id) {
        return {
          ...post,
          totalLikes: payload.totalLikes,
          like: payload.totalLikes,
          isLiked: payload.isLiked,
        };
      }
      return post;
    });

    state.currentuserpost = state.currentuserpost.map((post) => {
      if (post._id == payload._id) {
        return payload;
      }
      return post;
    });
  },
  SET_CURRENT_USER_ALL_POSTS(state, payload) {
    state.currentuserpost = payload;
    // state.posts = payload;
  },
  LOAD_CURRENT_USER_POST_COMMENT(state, payload) {
    state.currentuserpostComments[payload._id] = payload.comments;
  },
  LOAD_CURRENT_USER_COMMENT_REPLY() {
    state.currentusercommentReplies[payload._id] = payload.replies;
  },
  SET_SAVED_POST(state, payload) {
    state.savedPost = payload;
  },
  SET_ISREPLYOPEN(state, payload) {
    // let postIndex = state.posts.findIndex(
    //   (x) => x._id == state.selectedPost._id
    // );
    let commentIndex = state.selectedPost.comments.findIndex(
      (x) => x._id == payload._id
    );
    if (
      state.selectedPost.comments[commentIndex].isReplyOpen === true ||
      state.selectedPost.comments[commentIndex].isReplyOpen === false
    ) {
      state.selectedPost.comments[commentIndex].isReplyOpen =
        !state.selectedPost.comments[commentIndex].isReplyOpen;
    } else {
      state.selectedPost.comments[commentIndex].isReplyOpen = false;
    }
  },
  LIKE_COMMENT(state, payload) {
    // console.log(payload);
    let postIndex = state.posts.findIndex(
      (x) => x._id == state.selectedPost._id
    );

    if (payload.liketype == "Comment") {
      // state.postComments[payload.data.parentId] = state.postComments[
      //   payload.data.parentId
      // ].map((postComment) => {
      //   if (postComment._id == payload.data._id) {
      //     return payload.data;
      //   }
      //   return postComment;
      // });
      let commentIndex = state.posts[postIndex].comments.findIndex(
        (x) => x._id == payload.data._id
      );
      // console.log(state.selectedPost.comments[commentIndex].isReplyOpen);

      Object.assign(payload.data, {
        isReplyOpen: state.selectedPost.comments[commentIndex].isReplyOpen
          ? state.selectedPost.comments[commentIndex].isReplyOpen
          : false,
      });

      state.posts[postIndex].comments.splice(commentIndex, 1, payload.data);
      state.selectedPost.comments.splice(commentIndex, 1, payload.data);
      // state.posts[postIndex].comments[commentIndex].likes.push(payload.data);
      // state.posts[postIndex].comments[commentIndex].totalLikes += 1;

      // state.selectedPost.comments[commentIndex].likes.push(payload.data);
      // state.selectedPost.comments[commentIndex].totalLikes += 1;
    } else if (payload.liketype == "Reply") {
      // let Data = state.commentReplies[payload.data.parentId].map(
      //   (postComment) => {
      //     if (postComment._id == payload.data._id) {
      //       return payload.data;
      //     }
      //     return postComment;
      //   }
      // );
      // Vue.set(state.commentReplies, payload.data.parentId, Data);
      let commentIndex = state.posts[postIndex].comments.findIndex(
        (x) => x._id == payload.parentId
      );
      const replyIndex = state.posts[postIndex].comments[
        commentIndex
      ].replies.findIndex((x) => x._id == payload.data._id);
      state.posts[postIndex].comments[commentIndex].replies.splice(
        replyIndex,
        1,
        payload.data
      );
      state.selectedPost.comments[commentIndex].replies.splice(
        replyIndex,
        1,
        payload.data
      );
      // state.posts[postIndex].comments[commentIndex].replies[
      //   replyIndex
      // ].likes.push(payload.data);

      // state.posts[postIndex].comments[commentIndex].replies[
      //   replyIndex
      // ].totalLikes += 1;
      // state.selectedPost.comments[commentIndex].replies[replyIndex].likes.push(
      //   payload.data
      // );
      // state.selectedPost.comments[commentIndex].replies[
      //   replyIndex
      // ].totalLikes += 1;
    }

    // state.commentReplies[payload.parentId] = state.commentReplies[
    //   payload.parentId
    // ].map((postComment) => {
    //   if (postComment._id == payload._id) {
    //     return payload;
    //   }
    //   return postComment;
    // });

    // state.currentuserpostComments = state.currentuserpostComments.map(
    //   (postComments) => {
    //     if (postComments._id == payload.data._id) {
    //       return payload.data;
    //     }
    //     return postComments;
    //   }
    // );
  },
  UNLIKE_COMMENT(state, payload) {
    let postIndex = state.posts.findIndex(
      (x) => x._id == state.selectedPost._id
    );

    if (payload.liketype == "Comment") {
      // let Data = state.postComments[payload.data.parentId].map(
      //   (postComment) => {
      //     if (postComment._id == payload.data._id) {
      //       return payload.data;
      //     }
      //     return postComment;
      //   }
      // );
      // Vue.set(state.postComments, payload.data.parentId, Data);
      let commentIndex = state.posts[postIndex].comments.findIndex(
        (x) => x._id == payload.data._id
      );
      Object.assign(payload.data, {
        isReplyOpen: state.selectedPost.comments[commentIndex].isReplyOpen
          ? state.selectedPost.comments[commentIndex].isReplyOpen
          : false,
      });
      state.posts[postIndex].comments.splice(commentIndex, 1, payload.data);
      state.selectedPost.comments.splice(commentIndex, 1, payload.data);
      // state.posts[postIndex].comments[commentIndex].likes = [];
      // state.posts[postIndex].comments[commentIndex].totalLikes -= 1;

      // state.selectedPost.comments[commentIndex].likes = [];
      // state.selectedPost.comments[commentIndex].totalLikes -= 1;
    } else if (payload.liketype == "Reply") {
      // let Data = state.commentReplies[payload.data.parentId].map(
      //   (postComment) => {
      //     if (postComment._id == payload.data._id) {
      //       return payload.data;
      //     }
      //     return postComment;
      //   }
      // );

      // Vue.set(state.commentReplies, payload.data.parentId, Data);
      let commentIndex = state.posts[postIndex].comments.findIndex(
        (x) => x._id == payload.parentId
      );
      const replyIndex = state.posts[postIndex].comments[
        commentIndex
      ].replies.findIndex((x) => x._id == payload.data._id);
      state.posts[postIndex].comments[commentIndex].replies.splice(
        replyIndex,
        1,
        payload.data
      );
      state.selectedPost.comments[commentIndex].replies.splice(
        replyIndex,
        1,
        payload.data
      );
      // state.posts[postIndex].comments[commentIndex].replies[replyIndex].likes =
      //   [];
      // state.posts[postIndex].comments[commentIndex].replies[
      //   replyIndex
      // ].totalLikes -= 1;
      // state.selectedPost.comments[commentIndex].replies[replyIndex].likes = [];
      // state.selectedPost.comments[commentIndex].replies[
      //   replyIndex
      // ].totalLikes -= 1;
    }

    // state.currentuserpostComments = state.currentuserpostComments.map(
    //   (postComments) => {
    //     if (postComments._id == payload.data._id) {
    //       return payload.data;
    //     }
    //     return postComments;
    //   }
    // );
  },
  DELETE_COMMENT(state, payload) {
    let postIndex = state.posts.findIndex(
      (post) => post._id == payload.parentId
    );
    if (state.posts[postIndex] && state.posts[postIndex].totalComments) {
      state.posts[postIndex].totalComments -= 1;
    }

    Vue.set(state.commentReplies, payload._id, []);

    let Data = state.postComments[payload.parentId].filter(
      (postComment) => postComment._id !== payload._id
    );

    Vue.set(state.postComments, payload.parentId, Data);
  },
  DELETE_REPLY(state, payload) {
    let CommentIndex = state.postComments[payload.postId].findIndex(
      (Comment) => Comment._id == payload.parentId
    );
    if (
      state.postComments[payload.postId][CommentIndex] &&
      state.postComments[payload.postId][CommentIndex].totalReplies
    ) {
      state.postComments[payload.postId][CommentIndex].totalReplies -= 1;
    }

    let Data = state.commentReplies[payload.parentId].filter(
      (Reply) => Reply._id !== payload._id
    );

    Vue.set(state.commentReplies, payload.parentId, Data);
  },
  POLLVOTE(state, payload) {
    let postIndex = state.posts.findIndex((post) => post._id == payload.postId);

    state.posts[postIndex].poll = payload.data;
  },
  SET_SELECTED_POST(state, payload) {
    payload.comments = payload.comments.map((x) => ({
      ...x,
      isReplyOpen: false,
      replyText: "",
    }));
    state.selectedPost = payload;
  },
  SELECTED_POST_LIKES(state, payload) {
    state.viewLikes = payload;
  },
  DELETE_POST(state, payload) {
    const postIndex = state.posts.findIndex((x) => x._id == payload.postID);
    state.posts.splice(postIndex, 1);
  },
  DELETE_ANY_POST(state, payload) {
    const postIndex = state.posts.findIndex((x) => x._id == payload.postID);
    state.posts.splice(postIndex, 1);
  },
  SET_NEW_POSTS(state, payload) {
    state.posts.push(...payload);
  },
  SET_SELECT_POST(state, payload) {
    state.select_post = payload;
  },
  EDIT_POST(state, payload) {
    state.edit_post = payload;
  },
};
