import RepositoryFactory from "@/apis";
const homeSeo = RepositoryFactory.homeSeo;

export default {
  AddHomeSeo({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await homeSeo.AddHomeSeo(payload);

        commit("ADD_HOME_SEO", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  GetHomeSeo({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await homeSeo.GetHomeSeo(payload);
        console.log(data.data);
        commit("SET_HOME_SEO", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  EditShomeSeo({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await homeSeo.EditShomeSeo(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetSingleHomeSeo({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await homeSeo.GetSingleHomeSeo(payload);

        commit("GET_SINGLE_HOME_SEO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
