// import channel from "./channel";
import producthome from "./producthome";
import home from "./home";
import auth from "./auth";
import feed from "./feed";
import post from "./post";
import category from "./category";
import subcategory from "./subcategory";
import product from "./product";
import page from "./page";
import pagecategory from "./pagecategory";
import channel from "./channel";
import homeSeo from "./Seo/homeSeo";
import ptag from "./ptag";
import email from "./email";
import event from "./event";
import eventbooking from "./eventbooking";
import feedback from "./feedback";
import productsubcategory from "./productsubcategory";
import investment from "./investment";
import Transaction from "./Transaction";
import orders from "./orders";
import Banner from "./Banner";
import Testimonial from "./testimonial";
import PressRelease from "./pressRelease";

export default {
  auth: auth,
  channel: channel,
  home: home,
  producthome: producthome,
  category: category,
  subcategory: subcategory,
  product: product,
  page: page,
  pagecategory: pagecategory,
  // feed: feed,
  // post: post,
  feed: feed,
  post: post,
  // user: user,
  homeSeo: homeSeo,
  ptag: ptag,
  email: email,
  event: event,
  eventbooking: eventbooking,
  feedback: feedback,
  productsubcategory: productsubcategory,
  investment: investment,
  Transaction: Transaction,
  orders,
  Banner,
  Testimonial,
  PressRelease,
};
