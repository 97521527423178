<template>
  <div>
    <!-- <div
      class="lg:block md:block hidden"
      :class="[
        'g-cursor',
        { 'g-cursor_hover': hover },
        { 'g-cursor_hide': hideCursor },
      ]"
    >
      <div :style="cursorCircle" class="g-cursor__circle"></div>
      <div class="g-cursor__point" ref="point" :style="cursorPoint"></div>
    </div> -->

    <router-view />
  </div>
</template>

<script>
import gql from "graphql-tag";
export default {
  data() {
    return {
      xChild: 0,
      yChild: 0,
      xParent: 0,
      yParent: 0,
      hover: false,
      hideCursor: true,
    };
  },
  async mounted() {
    // const response = await this.$apollo.query({
    //   query: gql`
    //     query {
    //       allUsers {
    //         id
    //         email
    //       }
    //     }
    //   `,
    // });
    // console.log(response.data.allUsers);
  },
  // computed: {
  //   cursorCircle() {
  //     return `transform: translateX(${this.xParent}px) translateY(${this.yParent}px) translateZ(0) translate3d(0, 0, 0);`;
  //   },
  //   cursorPoint() {
  //     return `transform: translateX(${this.xChild - 3}px) translateY(${
  //       this.yChild - 3
  //     }px) translateZ(0) translate3d(0, 0, 0);`;
  //   },
  // },
  methods: {
    moveCursor(e) {
      this.xChild = e.clientX;
      this.yChild = e.clientY;
      setTimeout(() => {
        this.xParent = e.clientX - 15;
        this.yParent = e.clientY - 15;
      }, 100);
    },
  },
  // mounted() {
  //   document.addEventListener("mousemove", this.moveCursor);
  //   document.addEventListener("mouseleave", (e) => {
  //     this.hideCursor = true;
  //   });
  //   document.addEventListener("mouseenter", (e) => {
  //     this.hideCursor = false;
  //   });
  // },
};
</script>

<style lang="scss">
#app {
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.g-cursor {
  &_hide {
    opacity: 0;
    width: 60px;
    height: 60px;
    transition: width 0.6s ease, height 0.6s ease, opacity 0.6s ease;
    position: absolute;
  }

  &__circle {
    pointer-events: none;
    user-select: none;
    top: 0;
    left: 0;
    position: fixed;
    width: 30px;
    height: 30px;
    border: 2px solid #e74747;
    border-radius: 100%;
    z-index: 5555;
    backface-visibility: hidden;
    transition: opacity 0.6s ease;
  }

  &__point {
    top: 0;
    left: 0;
    position: fixed;
    width: 10px;
    height: 10px;
    pointer-events: none;
    user-select: none;
    border-radius: 100%;
    background: #e74747;
    z-index: 55555555;
    backface-visibility: hidden;
    will-change: transform;
  }

  &_hover {
    .g-cursor__circle {
      opacity: 0;
      width: 60px;
      height: 60px;
      transition: width 0.6s ease, height 0.6s ease, opacity 0.6s ease;
    }
  }
}
</style>
