import RepositoryFactory from "@/apis";
const event = RepositoryFactory.event;

export default {
  CreateEvent({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await event.CreateEvent(payload);

        // commit("", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  UpdateEvent({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await event.UpdateEvent(payload);

        // commit("", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  DeleteEvent({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await event.DeleteEvent(payload);

        commit("DELETE_EVENT", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  GetEventbyTagid({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await event.GetEventbyTagid(payload);

        commit("GET_EVENTTAGID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  GetALLEventbyTagid({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await event.GetALLEventbyTagid(payload);

        commit("GET_ALLEVENTTAGID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  GetSingleEvent({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await event.GetSingleEvent(payload);

        // commit("GET_SINGLE_PAGE_CATEGORY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetEvent({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await event.GetEvent(payload);

        commit("GET_ALL_EVENT", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetAllEvent({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await event.GetAllEvent(payload);

        commit("GET_ALL_EVENT_SEARCH", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
