import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const GETCATEGORY = "gettag";
const GETTAGALL = "gettagall";
const ADDTAG = "addtag";
const UPDATETAG = "updatetag";
const DELETETAG = "deletetag";

export default {
  GetCategory(payload) {
    return Repository.get(transformRoute(GETCATEGORY), payload);
  },
  AddTag(payload) {
    return Repository.post(transformRoute(ADDTAG), payload, formDataConfig);
  },
  UpdateTag(payload) {
    return Repository.post(transformRoute(UPDATETAG), payload, formDataConfig);
  },
  DeleteTag(payload) {
    return Repository.post(transformRoute(DELETETAG), payload);
  },
  GetTagAll(payload) {
    return Repository.post(transformRoute(GETTAGALL), payload);
  },
};

const transformRoute = (route) => {
  return `/ptag/${route}`;
};
