import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const BOOKEVENT = "bookevent";
const USERCHECKED = "userchecked";

export default {
  userchecked(payload) {
    return Repository.post(transformRoute(USERCHECKED), payload);
  },

  BookEvent(payload) {
    return Repository.post(transformRoute(BOOKEVENT), payload);
  },
  // GetSingleEvent(payload) {
  //   return Repository.post(transformRoute(GETSINGLEEVENT), payload);
  // },
};

const transformRoute = (route) => {
  return `/eventbooking/${route}`;
};
