export default {
  visibleMembers: (state) => {
    return (
      state.selectedChannelMembers.length &&
      state.selectedChannelMembers.slice(0, 6)
    );
  },
  currentUserChannel: (state) => {
    return state.channels.filter((data) => data.isMember);
  },

  nonMemberChannels: (state) => {
    return state.channels.filter((data) => !data.isMember);
  },
};
