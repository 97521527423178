import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const CREATEEVENT = "addevent";
const GETEVENT = "getevent";
const GETALLEVENT = "getAllevent";
const GETSINGLEEVENT = "getSingleevent";
const UPDATEEVENT = "updateevent";
const DELETEEVENT = "deleteevent";
const GETEVENTBYTAGID = "geteventbytagid";
const GETALLEVENTBYTAGID = "getALLeventbytagid";
export default {
  GetEvent(payload) {
    return Repository.post(transformRoute(GETEVENT), payload);
  },
  GetAllEvent(payload) {
    return Repository.post(transformRoute(GETALLEVENT), payload);
  },

  CreateEvent(payload) {
    console.log(payload);
    return Repository.post(
      transformRoute(CREATEEVENT),
      payload,
      formDataConfig
    );
  },
  UpdateEvent(payload) {
    console.log(payload);
    return Repository.post(
      transformRoute(UPDATEEVENT),
      payload,
      formDataConfig
    );
  },
  GetSingleEvent(payload) {
    return Repository.post(transformRoute(GETSINGLEEVENT), payload);
  },
  GetEventbyTagid(payload) {
    return Repository.post(transformRoute(GETEVENTBYTAGID), payload);
  },
  GetALLEventbyTagid(payload) {
    return Repository.post(transformRoute(GETALLEVENTBYTAGID), payload);
  },
  DeleteEvent(payload) {
    return Repository.post(transformRoute(DELETEEVENT), payload);
  },
};

const transformRoute = (route) => {
  return `/event/${route}`;
};
