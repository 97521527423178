import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const ADD_ENTRY = "add";
const GET_ENTRY = "get";
const UPDATE_ENTRY = "Update";

export default {
  getAllCollection(payload) {
    return Repository.get(transformRoute(GET_ENTRY), payload);
  },

  addOneCollection(payload) {
    return Repository.post(transformRoute(ADD_ENTRY), payload);
  },
  UpdateOneCollection(payload) {
    return Repository.post(transformRoute(UPDATE_ENTRY), payload);
  },
};

const transformRoute = (route) => {
  return `/transaction/${route}`;
};
