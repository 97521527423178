export default {
  SET_CATEGORY(state, payload) {
    state.category = payload;
  },
  GET_SUBCAT_BY_ID(state, payload) {
    state.getsubcatbyid = payload;
  },
  GET_All_SUBCAT_BY_ID(state, payload) {
    state.getAllsubcatbyid = payload;
  },
  SUB_CAT_DETAILS(state, payload) {
    state.subcatdetail = payload;
  },
  DELETE_SUBCATEGORY(state, payload) {
    const index = state.getsubcatbyid.findIndex(
      (page) => page._id === payload._id
    );
    state.getsubcatbyid.splice(index, 1);
  },
};
