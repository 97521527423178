import RepositoryFactory from "@/apis";
const Transaction = RepositoryFactory.Transaction;

export default {
  addOneCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Transaction.addOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  UpdateOneCollection({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Transaction.UpdateOneCollection(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // UpdateCategory({ commit }, payload) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await category.UpdateCategory(payload);

  //       resolve(data);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },

  // DeleteCategory({ commit }, payload) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await category.DeleteCategory(payload);

  //       commit("DELETE_CATEGORY", data.data);

  //       resolve(data);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
};
