import RepositoryFactory from "@/apis";
const orders = RepositoryFactory.orders;

export default {
  // AddCategory({ commit }, payload) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await category.AddCategory(payload);

  //       resolve(data);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  UpdateOrder({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await orders.UpdateOrder(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getOrder({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await orders.getOrder(payload);

        commit("SET_ALLORDERS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getOrderByOrderId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await orders.getOrder(payload);

        // commit("SET_ALLORDERS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetUserOrder({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await orders.GetUserOrder(payload);

        commit("SET_USERORDERS", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // DeleteCategory({ commit }, payload) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await category.DeleteCategory(payload);

  //       commit("DELETE_CATEGORY", data.data);

  //       resolve(data);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
};
