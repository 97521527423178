import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const ADDPAGE = "addpage";
const GET = "getpage";
const GETSINGLEPAGE = "getSinglpage";
const GETPAGEBYID = "getpagesbyid";
const GETALLPAGEBYID = "getallpagesbyid";
const EDITPAGES = "updatepage";
const INSERTPAGE = "insertpage";
const GETPAGEBYTAGID = "getpagebytagid";

const GETPODCASTBYID = "getpodcastpagesbyid";
const REMOVEPAGES = "deletepage";
const IMAGEUPLOADER = "imageuploader";

export default {
  AddPage(payload) {
    return Repository.post(transformRoute(ADDPAGE), payload, formDataConfig);
  },

  GetPage(payload) {
    return Repository.get(transformRoute(GET), payload);
  },

  GetSinglePage(payload) {
    return Repository.post(transformRoute(GETSINGLEPAGE), payload);
  },
  GetPageById(payload) {
    return Repository.post(transformRoute(GETPAGEBYID), payload);
  },
  GetAllPageById(payload) {
    return Repository.post(transformRoute(GETALLPAGEBYID), payload);
  },
  getpagebytagid(payload) {
    return Repository.post(transformRoute(GETPAGEBYTAGID), payload);
  },
  GetPodCastPageById(payload) {
    return Repository.post(transformRoute(GETPODCASTBYID), payload);
  },
  EditPages(payload) {
    return Repository.post(transformRoute(EDITPAGES), payload, formDataConfig);
  },
  RemovePages(payload) {
    return Repository.post(transformRoute(REMOVEPAGES), payload);
  },
  insertPage(payload) {
    return Repository.post(transformRoute(INSERTPAGE), payload);
  },
  imageUploader(payload) {
    return Repository.post(
      transformRoute(IMAGEUPLOADER),
      payload,
      formDataConfig
    );
  },
};

const transformRoute = (route) => {
  return `/page/${route}`;
};
