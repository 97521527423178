export default {
  SET_username(state, payload) {
    state.username = payload;
  },

  UPDATE_USER_INFO(state, payload) {
    state.user = payload;
  },
  profileUpdate(state, payload) {
    state.updatedData = payload;
  },
  UPDATE_USER_COVER_IMAGE(state, payload) {
    state.user.coverImage = payload;
  },
  UPDATE_USER_IMAGE(state, payload) {
    state.user.userImage = payload;
  },
  userData(state, payload) {
    state.userData = payload;
  },

  GET_ALL_USER(state, payload) {
    state.userAllData = payload;
  },
  GET_ALL_USER_SEARCH(state, payload) {
    state.userAllSearchData = payload;
  },

  UPDATE_USERDATA_INFO(state, payload) {
    state.userData.student.designation = payload.student.designation;
    state.userData.student.location = payload.student.location;
    state.userData.student.aboutMe = payload.student.aboutMe;
    state.userData.mobile = payload.user.mobile;
    state.userData.email = payload.user.email;
    state.userData.firstname = payload.user.firstname;
    state.userData.lastname = payload.user.lastname;
  },
  updateProfile(state, payload) {
    state.userProfile = payload;
  },
  GETSINGLE_PROFILE(state, payload) {
    state.getProfile = payload;
  },
  logout(state, payload) {
    state.user = payload;
  },
  USERINFO(state, payload) {
    state.userinfo = payload;
  },
};
