export default {
  product: [],
  getproductbysubcatid: [],
  getallproductbysubcatid: {},
  getsingleproduct: {},
  getproductbycatid: [],
  selectedCat: {},
  addproduct: {},
  getproductbyid: [],
  productdetails: {},
  delete_product: {},
  getproductbytagid: {},
};
