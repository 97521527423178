import state from "./state";

export default {
  // GET_BANNER(state, payload) {
  //   state.banner = payload.result;
  // },
  SET_BANNER(state, payload) {
    state.banner = payload;
  },
};
