import state from "./state";

export default {
  Total_Investment(state, payload) {
    state.total_investment = payload;
  },
  CATEGORY_DETAILS(state, payload) {
    state.category_details = payload;
  },
  ORDER_ADDED(state, payload) {
    state.order_status = payload;
  },
  SET_Startupbyid(state, payload) {
    state.startupbyid = payload;
  },
  DELETE_CATEGORY(state, payload) {
    const index = state.category.findIndex((page) => page._id == payload._id);
    state.category.splice(index, 1);
  },
  SET_INVESTMENTS_DETAILS(state, payload) {
    state.investments = payload;
  },
  SET_USER_INVESTMENTS_DETAILS(state, payload) {
    state.userInvestments = payload;
  },
  PAYMENT_INFO(state, payload) {
    state.paymentinfo = payload;
  },
  PRODUCT_PAYMENT_DETAILS(state, payload) {
    state.product_payment_details = payload;
  },
};
