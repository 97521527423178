import axios from "axios";
import { Encript, Decrypt } from "@/helpers/CryptoJS";
import _ from "lodash";
// You can use your own logic to set your local or production domain

const baseDomain = process.env.VUE_APP_BASE_URL_CORE_API;
const baseURL = `${baseDomain}`;

const userInfoLocalStorage = localStorage.getItem("token");

const API = axios.create({
  baseURL: baseURL,
  headers: {
    "project-suite": "Ecommerce",
    "community-name": process.env.VUE_APP_COMMUNITY_NAME,
  },
});

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    // console.log(config.headers["Content-Type"]);
    if (config.headers["Content-Type"] == "multipart/form-data") {
      // let FormData = require("form-data");

      let data = new FormData();
      data.append(
        "data",
        process.env.VUE_APP_ENCRYPTION != 0
          ? Encript(JSON.stringify(config.data.data))
          : JSON.stringify(config.data.data)
      );
      console.log(config.data);
      if (config.data["media"]) {
        data.append("media", config.data.media);
      }
      if (config.data["profilepic"]) {
        data.append("profilepic", config.data.profilepic);
      }
      if (config.data["image"]) {
        data.append("image", config.data.image);
      }
      if (config.data["productImage"] && config.data.productImage.length > 0) {
        config.data.productImage.forEach((file) => {
          data.append("productImage", file);
        });
      } else if (
        config.data["productImage"] &&
        typeof config.data.productImage == "string"
      ) {
        data.append("productImage", config.data.productImage);
      }

      if (config.data["categoryImage"]) {
        data.append("categoryImage", config.data.categoryImage);
      }
      if (config.data["partnerLogo"]) {
        data.append("partnerLogo", config.data.partnerLogo);
      }
      if (config.data["subcatImage"]) {
        data.append("subcatImage", config.data.subcatImage);
      }
      if (config.data["productsubcatImage"]) {
        data.append("productsubcatImage", config.data.productsubcatImage);
      }
      if (config.data["gallery"] && config.data.gallery.length > 0) {
        config.data.gallery.forEach((file) => {
          data.append("gallery", file);
        });
      } else if (
        config.data["gallery"] &&
        typeof config.data.gallery == "string"
      ) {
        data.append("gallery", config.data.gallery);
      }

      let formObj = {};
      for (var pair of data.entries()) {
        formObj[pair[0]] = pair[1];
      }
      console.log(formObj);
      config.data = data;

      // {
      //   data: Encript(JSON.stringify(config.data.data)),
      //   media: config.data.media,
      // };
    } else if (config.data) {
      // console.log(JSON.stringify(Object.fromEntries(config.data)));
      // console.log(Encript(config.data));
      config.data = {
        data:
          process.env.VUE_APP_ENCRYPTION != 0
            ? Encript(JSON.stringify(config.data))
            : JSON.stringify(config.data),
      };
      // config.data = {
      //   data: Encript(JSON.stringify(Object.fromEntries(config.data))),
      // };
    }
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },

  (error) => Promise.reject(error)
);
API.interceptors.response.use(
  (response) => {
    if (response) {
      if (Object.keys(response.data.data).length) {
        response.data.data = Decrypt(response.data.data);
      }
    }
    return response;
  },
  (error) => Promise.reject(error)
);

export default API;
