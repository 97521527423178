import RepositoryFactory from "@/apis";
const pressRelease = RepositoryFactory.PressRelease;

export default {
  CreatePressRelease({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await pressRelease.CreatePressRelease(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  GetPressRelease({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await pressRelease.GetPressRelease(payload);

        commit("SET_PRESSRELEASE", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  UpdatePressRelease({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await pressRelease.UpdatePressRelease(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  DeletePressRelease({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await pressRelease.DeletePressRelease(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  GetPressReleaseYears({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await pressRelease.GetPressReleaseYears(payload);
        commit("SET_GETPRESSRELEASEYEARS", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
