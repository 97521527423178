import {
  required,
  confirmed,
  length,
  email,
  alpha,
  regex,
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("email", {
  ...email,
  message: "This field must be a valid email",
});

extend("confirmed", {
  ...confirmed,
  message: "This field confirmation does not match",
});

extend("length", {
  ...length,
  message: "This field must have 2 options",
});
extend("alpha", {
  ...alpha,
  message: "This field must have only alphabates",
});
// extend("regex", {
//   ...regex,
//   message: "This field format is not valid",
// });
extend("url", {
  ...regex,
  message: "Invalid url format",
});
