import state from "./state";

export default {
  SET_ALLORDERS(state, payload) {
    state.orders = payload;
  },
  ORDERDETAILS(state, payload) {
    state.orderDetails = payload;
  },
  ORDERINFO(state, payload) {
    state.orderinfo = payload;
  },
  INVOCEDETAILS(state, payload)
  {
    state.invoiceDetails = payload;
  },
  SET_USERORDERS(state, payload)
  {
    state.userOrder = payload;
  },
  DELETE_CATEGORY(state, payload) {
    const index = state.category.findIndex((page) => page._id == payload._id);
    state.category.splice(index, 1);
  },
};
