import RepositoryFactory from "@/apis";
const subcategory = RepositoryFactory.subcategory;

export default {
  GetSubcategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.GetSubcategory(payload);

        commit("SET_SUBCATEGORY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetSubcatById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.GetSubcatById(payload);

        commit("GET_SUBCAT_BY_ID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetSubcatByIdNoCommit({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.GetSubcatById(payload);

        // commit("GET_SUBCAT_BY_ID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetAllSubcatById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.GetAllSubcatById(payload);

        commit("GET_All_SUBCAT_BY_ID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  AddSubcategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.AddSubcategory(payload);

        // commit("GET_SUBCAT_BY_ID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  UpdateSubcategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.UpdateSubcategory(payload);

        // commit("GET_SUBCAT_BY_ID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  DeleteSubCat({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await subcategory.DeleteSubCat(payload);

        commit("DELETE_SUBCATEGORY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
