import RepositoryFactory from "@/apis";
const product = RepositoryFactory.product;

export default {
  GetProduct({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.GetProduct(payload);

        if (payload && payload["offset"]) {
          commit("ADD_NEWPRODUCTS", data.data);
        } else {
          commit("SET_PRODUCT", data.data);
        }

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetProductBySubcatId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.GetProductBySubcatId(payload);

        commit("GET_PRODUCT_BY_SUBCATID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetAllProductBySubcatId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.GetAllProductBySubcatId(payload);

        commit("GET_ALL_PRODUCT_BY_SUBCATID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetSingleProduct({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.GetSingleProduct(payload);

        commit("GET_SINGLE_PRODUCT", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetProductByTagId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.GetProductByTagId(payload);

        commit("GET_PRODUCT_BYTAGID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetProductByCategoryId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.GetProductByCategoryId(payload);

        commit("GET_PRODUCT_BY_CATEGORYID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  AddProduct({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.AddProduct(payload);

        commit("CREATE_PRODUCT", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  AddSingleProduct({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.AddSingleProduct(payload);

        // commit("CREATE_SINGLE_PRODUCT", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  DeleteProduct({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.DeleteProduct(payload);

        commit("DELETE_PRODUCT", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  UpdateProduct({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await product.UpdateProduct(payload);

        // commit("DELETE_PRODUCT", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
