import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const GET = "getproductsubcategory";
const GETSUB = "getproductsubcatbyid";
const GETALLSUB = "getallproductsubcatbyid";
const ADDPRODUCTSUBCATEGORY = "addproductsubcategory";
const DELETE_SUB_PRODUCT = "deleteproductsubcategory";
const PRODUCTUPDATESUBCATEGORY = "updateproductsubcategory";

export default {
  GetAllProdSubcategory(payload) {
    return Repository.get(transformRoute(GET), payload);
  },
  GetProductSubcatById(payload) {
    return Repository.post(transformRoute(GETSUB), payload);
  },
  GetAllProductSubcatById(payload) {
    return Repository.post(transformRoute(GETALLSUB), payload);
  },
  DeleteProductSubCat(payload) {
    return Repository.post(transformRoute(DELETE_SUB_PRODUCT), payload);
  },
  AddProductSubcategory(payload) {
    return Repository.post(
      transformRoute(ADDPRODUCTSUBCATEGORY),
      payload,
      formDataConfig
    );
  },
  ProductUpdateSubcategory(payload) {
    return Repository.post(
      transformRoute(PRODUCTUPDATESUBCATEGORY),
      payload,
      formDataConfig
    );
  },
};

const transformRoute = (route) => {
  return `/productsubcategory/${route}`;
};
