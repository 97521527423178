export default {
  SET_PRODUCT(state, payload) {
    // state.product.push(...payload);
    state.product = payload;
  },
  GET_PRODUCT_BY_SUBCATID(state, payload) {
    state.getproductbysubcatid = payload;
  },
  GET_ALL_PRODUCT_BY_SUBCATID(state, payload) {
    state.getallproductbysubcatid = payload;
  },
  GET_SINGLE_PRODUCT(state, payload) {
    state.getsingleproduct = payload;
  },
  GET_PRODUCT_BY_CATEGORYID(state, payload) {
    state.getproductbyid = payload;
  },
  SELECTED_CAT(state, payload) {
    state.selectedCat = payload;
  },
  CREATE_PRODUCT(state, payload) {
    state.addproduct = payload;
  },
  PRODUCT_DETAIL(state, payload) {
    state.productdetails = payload;
  },
  ADD_NEWPRODUCTS(state, payload) {
    state.product.push(...payload);
  },
  GET_PRODUCT_BYTAGID(state, payload) {
    state.getproductbytagid = payload;
  },
  DELETE_PRODUCT(state, payload) {
    const index = state.getproductbysubcatid.findIndex(
      (page) => page._id === payload._id
    );
    state.getproductbysubcatid.splice(index, 1);
  },
};
