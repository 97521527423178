import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const GET = "getsubcategory";
const GETSUB = "getsubcatbyid";
const GETALLSUB = "getallsubcatbyid";
const ADDSUBCATEGORY = "addsubcategory";
const DELETE_SUB = "deletesubcategory";
const UPDATESUBCATEGORY = "updatesubcategory";

export default {
  GetSubcategory(payload) {
    return Repository.get(transformRoute(GET), payload);
  },
  GetSubcatById(payload) {
    return Repository.post(transformRoute(GETSUB), payload);
  },
  GetAllSubcatById(payload) {
    return Repository.post(transformRoute(GETALLSUB), payload);
  },
  DeleteSubCat(payload) {
    return Repository.post(transformRoute(DELETE_SUB), payload);
  },
  AddSubcategory(payload) {
    return Repository.post(
      transformRoute(ADDSUBCATEGORY),
      payload,
      formDataConfig
    );
  },
  UpdateSubcategory(payload) {
    return Repository.post(
      transformRoute(UPDATESUBCATEGORY),
      payload,
      formDataConfig
    );
  },
};

const transformRoute = (route) => {
  return `/subcategory/${route}`;
};
