export default {
  OPEN_LOGIN_POP(state, payload) {
    state.loginPop = payload;
    state.regPop = false;
  },
  OPEN_REG_POP(state, payload) {
    state.regPop = payload;
    state.loginPop = false;
  },
  CLOSE_ALL_POP(state, payload) {
    state.regPop = false;
    state.loginPop = false;
  },
  OPEN_CATEGORY_BLOGS(state, payload) {
    state.catBlog = payload;
  },
};
