import Repository from "../Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const GET = "get";
const ADD = "add";
const GETSINGLE = "getsingle";
const EDIT = "edit";

export default {
  GetHomeSeo(payload) {
    return Repository.get(transformRoute(GET), payload);
  },
  AddHomeSeo(payload) {
    return Repository.post(transformRoute(ADD), payload);
  },
  GetSingleHomeSeo(payload) {
    return Repository.post(transformRoute(GETSINGLE), payload);
  },
  EditShomeSeo(payload) {
    return Repository.post(transformRoute(EDIT), payload);
  },
};

const transformRoute = (route) => {
  return `/homeseo/${route}`;
};
