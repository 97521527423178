import RepositoryFactory from "@/apis";
const pagecategory = RepositoryFactory.pagecategory;

export default {
  GetPageCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await pagecategory.GetPageCategory(payload);
        console.log("this is blog category" + data);
        commit("SET_PAGE_CATEGORY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  GetSinglePageCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await pagecategory.GetSinglePageCategory(payload);
        console.log("data is" + JSON.stringify(data));
        commit("GET_SINGLE_PAGE_CATEGORY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetPageCategoryById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await pagecategory.GetPageCategoryById(payload);
        console.log("data is" + JSON.stringify(data));
        commit("GET_PAGE_CATEGORY_BYID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
