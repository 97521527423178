import RepositoryFactory from "@/apis";
const Banner = RepositoryFactory.Banner

export default {
  Get({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Banner.Get(payload);

        commit("SET_BANNER", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  Add({  }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Banner.Add(payload);

        // commit("Add_Category", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  Update({  }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Banner.Update(payload);

        // commit("Add_Category", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  Delete({  }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Banner.Delete(payload);

        // commit("Add_Category", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GETSINGLE({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Banner.GETSINGLE(payload);

        commit("GETSINGLE", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  Add({  }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await Banner.Add(payload);

        // commit("Add_Category", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
