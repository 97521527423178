export default {
  SET_BLOGCATEGORY(state, payload) {
    state.set_blogcat = payload;
  },
  Add_Category(state, payload) {
    state.add_category = payload;
  },
  SET_TAGALL(state, payload) {
    state.tags = payload;
  },
  SET_EDITTAG(state, payload) {
    state.editTag = payload;
  },
};
