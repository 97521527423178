import Repository from "./Repository";
const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const REGISTER = "/signupWithPassword";
const GENERATE_OTP = "/GenerateOTP";
const VERIFYOTP = "/VerifyOTP";
const LOGIN = "/loginWithPassword";
const LOGOUT = "/logout";
const FORGOT_PASSWORD = "/forgotPassword";
const UPDATEPROFILE = "/updateprofile";
const GETSINGLE_USER = "/getsingleUser";
const GETALLUSER = "/getAllUser";
const GETALLUSERSEARCH = "/getAllUserSearch";
const ADDADDRESS = "/addAddress";

export default {
  signupWithPassword(payload) {
    return Repository.post(transformRoute(REGISTER), payload);
  },
  generateOTP(payload) {
    return Repository.post(transformRoute(GENERATE_OTP), payload);
  },
  VerifyOTP(payload) {
    return Repository.post(transformRoute(VERIFYOTP), payload);
  },
  loginWithPassword(payload) {
    return Repository.post(transformRoute(LOGIN), payload);
  },
  logout(payload) {
    return Repository.post(transformRoute(LOGOUT), payload);
  },
  forgotPassword(payload) {
    return Repository.post(transformRoute(FORGOT_PASSWORD), payload);
  },
  addAddress(payload) {
    return Repository.post(transformRoute(ADDADDRESS), payload);
  },
  getAllUser(payload) {
    return Repository.post(transformRoute(GETALLUSER), payload);
  },
  getAllUserSearch(payload) {
    return Repository.post(transformRoute(GETALLUSERSEARCH), payload);
  },
  updateProfile(payload) {
    return Repository.post(
      transformRoute(UPDATEPROFILE),
      payload,
      formDataConfig
    );
  },
  getsingleProfile(payload) {
    return Repository.post(transformRoute(GETSINGLE_USER), payload);
  },
};

const transformRoute = (route) => {
  return `/auth${route}`;
};
