import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const CREATEFEEDBACK = "createfeedback";
// const GETEVENT = "getevent";
// const GETSINGLEEVENT = "getSingleevent";
export default {
  //   GetEvent(payload) {
  //     return Repository.get(transformRoute(GETEVENT), payload);
  //   },

  CreateFeedback(payload) {
    return Repository.post(transformRoute(CREATEFEEDBACK), payload);
  },
  //   GetSingleEvent(payload) {
  //     return Repository.post(transformRoute(GETSINGLEEVENT), payload);
  //   },
};

const transformRoute = (route) => {
  return `/feedback/${route}`;
};
