import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

const channel = "channel/";
const USER = "user/";
const getNewPosts = "channel/";
export default {
  getChannelFeeds(payload) {
    if (payload["_id"]) {
      return Repository.get(
        transformRoute(channel) + payload.id + "?_id=" + payload._id
      );
    } else {
      return Repository.get(transformRoute(channel) + payload.id);
    }
  },
  getUserChannelFeeds(payload) {
    if (payload["_id"]) {
      return Repository.get(
        transformRoute(channel) + payload.id + "?_id=" + payload._id
      );
    } else {
      return Repository.get(transformRoute(USER) + payload.id);
    }
  },
  getNewPosts(payload) {
    if (payload["_id"]) {
      return Repository.get(
        transformRoute(channel) +
          payload.id +
          "?_id=" +
          payload._id +
          "&offset=" +
          payload.offset
      );
    } else {
      return Repository.get(
        transformRoute(channel) + payload.id + "?offset=" + payload.offset
      );
    }
  },
};

const transformRoute = (route) => {
  return `/feed/${route}`;
};
