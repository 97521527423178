import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const GETORDER = "getOrders";
const UPDATEORDER = "update";
const GETUSERORDER = "getProductsFromOrders";
// const DELETECATEGORY = "deletecategory";

export default {
  getOrder(payload) {
    return Repository.post(transformRoute(GETORDER), payload);
  },
  // AddCategory(payload) {
  //   return Repository.post(
  //     transformRoute(ADDCATEGORY),
  //     payload,
  //     formDataConfig
  //   );
  // },
  GetUserOrder(payload) {
    return Repository.post(transformRoute(GETUSERORDER), payload);
  },
  UpdateOrder(payload) {
    return Repository.post(transformRoute(UPDATEORDER), payload);
  },
  // DeleteCategory(payload) {
  //   return Repository.post(transformRoute(DELETECATEGORY), payload);
  // },
};

const transformRoute = (route) => {
  return `/order/${route}`;
};
