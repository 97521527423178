import RepositoryFactory from "@/apis";
const producthome = RepositoryFactory.producthome;

export default {
  GetProductHome({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await producthome.GetProductHome(payload);

        commit("SET_PRODUCT_HOME", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
