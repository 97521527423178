import RepositoryFactory from "@/apis";
const ptag = RepositoryFactory.ptag;

export default {
  GetCategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await ptag.GetCategory(payload);

        commit("SET_BLOGCATEGORY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  AddTag({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await ptag.AddTag(payload);

        commit("Add_Category", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetTagAll({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await ptag.GetTagAll(payload);

        commit("SET_TAGALL", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  UpdateTag({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await ptag.UpdateTag(payload);

        // commit("SET_TAGALL", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  DeleteTag({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await ptag.DeleteTag(payload);

        // commit("SET_TAGALL", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
