import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const GET = "getcategory";
const GETALLCATEGORY = "getallcategory";
const ADDCATEGORY = "addcategory";
const UPDATECATEGORY = "updatecategory";
const DELETECATEGORY = "deletecategory";

export default {
  GetCategory(payload) {
    return Repository.get(transformRoute(GET), payload);
  },
  GetAllCategory(payload) {
    return Repository.post(transformRoute(GETALLCATEGORY), payload);
  },
  AddCategory(payload) {
    return Repository.post(
      transformRoute(ADDCATEGORY),
      payload,
      formDataConfig
    );
  },
  UpdateCategory(payload) {
    return Repository.post(
      transformRoute(UPDATECATEGORY),
      payload,
      formDataConfig
    );
  },
  DeleteCategory(payload) {
    return Repository.post(transformRoute(DELETECATEGORY), payload);
  },
};

const transformRoute = (route) => {
  return `/category/${route}`;
};
