import Vue from "vue";
import router from "@/router";
import App from "./App.vue";
import "@/assets/scss/Index.scss";
import VuePageTransition from "vue-page-transition";
import ElementUI from "element-ui";
import VueHead from "vue-head";
import locale from "element-ui/lib/locale/lang/en";
Vue.use(ElementUI, { locale });
import "element-ui/lib/theme-chalk/index.css";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import VueMeta from "vue-meta";
import VueCookies from "vue-cookies";
// ..
import "material-icons/iconfont/material-icons.css";
AOS.init();
Vue.use(VuePageTransition);
Vue.use(VueMeta);
Vue.use(VueCookies);
import VueSweetalert2 from "vue-sweetalert2";
// import "@/core-components/assets/scss/index.scss";
// import MenuIcon from "vue-material-design-icons/Menu.vue";
// import "vue-material-design-icons/styles.css";
// Vue.component("menu-icon", MenuIcon);
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
// import "boxicons/css/boxicons.min.css";
import Vuesax from "vuesax";
// import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import VueTimeago from "vue-timeago";
// import apollo from "./apolloClient";
// Vue.use(VueMaterial);
// import VueQuillEditor from "vue-quill-editor";

// import "quill/dist/quill.core.css"; // import styles
// import "quill/dist/quill.snow.css"; // for snow theme
// import "quill/dist/quill.bubble.css"; // for bubble theme

// Vue.use(VueQuillEditor /* { default global options } */);
import "vuesax/dist/vuesax.css"; //Vuesax styles

import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
Vue.use(VueTimeago, {
  name: "timeago",
  locale: "en",
  // locales:{
  //   'en-IND':require('vue-timeago/locales/en-IND.json')
  // }
  // timezone:
});
// Vue.use(
//   new VueSocketIO({
//     debug: true,
//     connection: SocketIO("https://hrx-api.aiab.in/api/ecomm", {
//       transport: ["websocket"],
//     }),
//   })
// );

Vue.use(Vuesax, {
  // options here
});
Vue.filter("two_digits", function (value) {
  if (value.toString().length <= 1) {
    return "0" + value.toString();
  }
  return value.toString();
});

require("vue2-animate/dist/vue2-animate.min.css");
Vue.use(VueSweetalert2);
import "./assets/scss/tailwind.css";
import "./vee-validate.js";
Vue.config.productionTip = false;
// Vue.prototype.$apollo = apollo;
Vue.prototype.$imageURL = process.env.VUE_APP_IMAGE_URL;
Vue.use(VueHead);
// Vuex Store
import store from "./store/index.js";

Vue.use(ElementUI);
new Vue({
  store,
  router,
  Vuesax,
  render: (h) => h(App),
}).$mount("#app");
