export default {
  GET_ALL_EVENT(state, payload) {
    state.get_allevent = payload;
    console.log(state.data);
  },
  GET_ALL_EVENT_SEARCH(state, payload) {
    state.get_allEventSearch = payload;
  },
  DELETE_EVENT(state, payload) {
    const index = state.get_allevent.findIndex(
      (page) => page._id === payload._id
    );
    state.get_allevent.splice(index, 1);
  },
  GET_EVENT_DETAILS(state, payload) {
    state.get_eventdetails = payload;
  },
  GET_EVENTTAGID(state, payload) {
    state.get_eventbytagid = payload;
  },
  GET_ALLEVENTTAGID(state, payload) {
    state.get_alleventbytagid = payload;
  },
  GET_ALLEVENTUSERS(state, payload) {
    state.get_alleventbyUser = payload;
  },
};
