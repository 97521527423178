export default {
  ADD_PAGE(state, payload) {
    state.addpage = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },

  GET_SINGLE_PAGE(state, payload) {
    state.getsinglepage = payload;
  },
  GET_PAGE_BYID(state, payload) {
    state.getpagesbyid = payload;
  },
  GET_ALL_PAGE_BYID(state, payload) {
    state.getallpagesbyid = payload;
  },
  GET_PODCAST_BYID(state, payload) {
    state.getpodcastbyid = payload;
  },
  INSERT_Pages(state, payload) {
    state.insertpages = payload;
  },
  Tag_Id(state, payload) {
    state.tagid = payload;
  },
  PAGE_DETAILS(state, payload) {
    state.page_details = payload;
  },
  GET_PAGEBYTAGID(state, payload) {
    state.page_bytagid = payload;
  },
  DELETE_PAGES(state, payload) {
    const index = state.getpagesbyid.data.findIndex(
      (page) => page._id === payload._id
    );
    state.getpagesbyid.data.splice(index, 1);
  },
};
