import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const GET= "get";
const ADD = "add";
const UPDATE = "updateBanner";
const DELETE = "delete";
const GETSINGLE = "getSingleBanner";

export default {
  Get(payload) {
    return Repository.post(transformRoute(GET), payload);
  },
  Add(payload) {
    return Repository.post(transformRoute(ADD), payload, formDataConfig);
  },
  Update(payload) {
    return Repository.post(transformRoute(UPDATE), payload, formDataConfig);
  },
  Delete(payload) {
    return Repository.post(transformRoute(DELETE), payload);
  },
  GETSINGLE(payload) {
    return Repository.post(transformRoute(GETSINGLE), payload);
  },
};

const transformRoute = (route) => {
  return `/Banner/${route}`;
};
