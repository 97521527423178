import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const GET = "getinvestment";
const GETSINGLE = "getstartupbyid";
const ORDERPAYMENT = "orderPayment";
const PAYMENT = "payment";
const GETINVESTMENTBYSTARTUP = "getInvestmentByStartupId";
const GETINVESTMENTBYUSER = "getInvestmentByUserId";
const CASHFREEPAYMENT = "cashfreePayment";
const PAYMENTCONFIRMATION = "paymentConfirmation";
//const DELETECATEGORY = "deletecategory";

export default {
  GetInvestment(payload) {
    return Repository.get(transformRoute(GET), payload);
  },
  Getstartupbyid(payload) {
    return Repository.post(transformRoute(GETSINGLE), payload);
  },
  OrderPayment(payload) {
    return Repository.post(transformRoute(ORDERPAYMENT), payload);
  },
  Payment(payload) {
    return Repository.post(transformRoute(PAYMENT), payload);
  },
  getInvestmentByStartupId(payload) {
    return Repository.post(transformRoute(GETINVESTMENTBYSTARTUP), payload);
  },
  getInvestmentByUserId(payload) {
    return Repository.post(transformRoute(GETINVESTMENTBYUSER), payload);
  },
  cashFreePayment(payload) {
    return Repository.post(transformRoute(CASHFREEPAYMENT), payload);
  },
  paymentConfirmation(payload) {
    return Repository.post(transformRoute(PAYMENTCONFIRMATION), payload);
  },
  // UpdateCategory(payload) {
  //   return Repository.post(
  //     transformRoute(UPDATECATEGORY),
  //     payload,
  //     formDataConfig
  //   );
  // },
  // DeleteCategory(payload) {
  //   return Repository.post(transformRoute(DELETECATEGORY), payload);
  // },
};

const transformRoute = (route) => {
  return `/payment/${route}`;
};
