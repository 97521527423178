import RepositoryFactory from "@/apis";
const investment = RepositoryFactory.investment;

export default {
  OrderPayment({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await investment.OrderPayment(payload);
        commit("ORDER_ADDED", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  Payment({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await investment.Payment(payload);
        // commit("INVESTMENT_ADDED", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  cashFreePayment({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await investment.cashFreePayment(payload);
        // commit("INVESTMENT_ADDED", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  paymentConfirmation({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await investment.paymentConfirmation(payload);
        commit("PAYMENT_INFO", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getInvestmentByStartupId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await investment.getInvestmentByStartupId(payload);
        commit("SET_INVESTMENTS_DETAILS", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getInvestmentByUserId({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await investment.getInvestmentByUserId(payload);
        commit("SET_USER_INVESTMENTS_DETAILS", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // UpdateCategory({ commit }, payload) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await category.UpdateCategory(payload);

  //       resolve(data);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  GetInvestment({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await investment.GetInvestment(payload);

        commit("Total_Investment", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  Getstartupbyid({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await startup.Getstartupbyid(payload);

        commit("SET_Startupbyid", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  // DeleteCategory({ commit }, payload) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await category.DeleteCategory(payload);

  //       commit("DELETE_CATEGORY", data.data);

  //       resolve(data);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
};
