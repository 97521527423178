import state from "./state";

export default {
  // GET_BANNER(state, payload) {
  //   state.banner = payload.result;
  // },
  ADD_HOME_SEO(state, payload) {
    state.homeSeo = payload;
  },
  SET_HOME_SEO(state, payload) {
    state.gethomeseo = payload;
  },
  GET_SINGLE_HOME_SEO(state, payload) {
    state.getSinglSeo = payload;
  },
};
