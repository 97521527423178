import RepositoryFactory from "@/apis";
const productsubcategory = RepositoryFactory.productsubcategory;

export default {
  GetAllProdSubcategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productsubcategory.GetAllProdSubcategory(
          payload
        );

        commit("GETALL_PRODSUBCATEGORY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetProductSubcatById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productsubcategory.GetProductSubcatById(payload);

        commit("GET_Product_SUBCAT_BY_ID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetProductSubcatByIdNoCommit({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productsubcategory.GetProductSubcatById(payload);

        // commit("GET_Product_SUBCAT_BY_ID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  GetAllProductSubcatById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productsubcategory.GetAllProductSubcatById(
          payload
        );

        commit("GET_All_Product_SUBCAT_BY_ID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  AddProductSubcategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productsubcategory.AddProductSubcategory(
          payload
        );

        // commit("GET_SUBCAT_BY_ID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  ProductUpdateSubcategory({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productsubcategory.ProductUpdateSubcategory(
          payload
        );

        // commit("GET_SUBCAT_BY_ID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  DeleteProductSubCat({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await productsubcategory.DeleteProductSubCat(payload);

        commit("DELETE_Product_SUBCATEGORY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
