import RepositoryFactory from "@/apis";
const feedback = RepositoryFactory.feedback;

export default {
  CreateFeedback({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await feedback.CreateFeedback(payload);

        // commit("SET_BLOGCATEGORY", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
