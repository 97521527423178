export default {
  SET_PAGE_CATEGORY(state, payload) {
    state.data = payload;
    console.log(state.data);
  },
  GET_SINGLE_PAGE__CATEGORY(state, payload) {
    state.getsinglepagecategory = payload;
  },
  GET_PAGE_CATEGORY_BYID(state, payload) {
    state.getpagecategorybyid = payload;
  },
};
