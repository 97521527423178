import RepositoryFactory from "@/apis";
const page = RepositoryFactory.page;

export default {
  AddPage({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.AddPage(payload);

        commit("ADD_PAGE", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetPage({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.GetPage(payload);

        commit("SET_PAGE", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getpagebytagid({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.getpagebytagid(payload);

        commit("GET_PAGEBYTAGID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  GetPageById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.GetPageById(payload);

        commit("GET_PAGE_BYID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetAllPageById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.GetAllPageById(payload);

        commit("GET_ALL_PAGE_BYID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetPodCastPageById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.GetPodCastPageById(payload);

        commit("GET_PODCAST_BYID", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetSinglePage({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.GetSinglePage(payload);

        commit("GET_SINGLE_PAGE", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  EditPages({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.EditPages(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  RemovePages({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.RemovePages(payload);
        commit("DELETE_PAGES", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  insertPage({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.insertPage(payload);

        commit("INSERT_Pages", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  imageUploader({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await page.imageUploader(payload);

        // commit("IMAGE_UPLOADER", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
