import RepositoryFactory from "@/apis";
const channel = RepositoryFactory.channel;

export default {
  CreateChannel({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.CreateChannel(payload);

        // Append in channel list
        commit("ADD_CHANNEL", { ...data.data, isMember: true });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetALLChannel({ commit, dispatch, state }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.GetAllChannel(payload);
        // console.log(data.data)
        commit("SET_ALL_CHANNELS", data.data);
        const index = data.data.result.findIndex((x) => x.isMember);
        if (index !== -1 && state.selectedChannel === null) {
          dispatch("GetChannel", { channelID: data.data.result[index]._id });
        }

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  GetChannel({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.GetChannel(payload);

        commit("SET_SELECTED_CHANNEL", data.data[0]);
        commit("SET_SELECTED_CHANNEL_MEMBERS", data.data[0].channelMembers);
        commit(
          "SET_SELECTED_CHANNEL_JOIN_REQUESTS",
          data.data[0].channelJoinRequests
        );

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  joinChannel({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.joinChannel(payload);

        commit("ADD_JOIN_REQUEST", payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  acceptJoin({ dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.acceptJoin(payload);

        delete payload.userID;
        dispatch("GetChannel", payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  rejectJoin({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.rejectJoin(payload);

        commit("REMOVE_JOIN_REQUEST", payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  removeMember({ dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.removeMember(payload);

        delete payload.UserID;
        dispatch("GetChannel", payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getNonMembers({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.getNonMembers(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addMember({ dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.addMember(payload);

        delete payload.UserID;
        dispatch("GetChannel", payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editChannel({ dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.editChannel(payload);

        dispatch("GetChannel", { channelID: payload.get("channelID") });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateRole({ dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.updateRole(payload);

        delete payload.channelMemberID;
        delete payload.role;
        dispatch("GetChannel", payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  DeleteChannel({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.DeleteChannel(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  GetMemberById({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await channel.GetMemberById(payload);
        commit("GET_MEMBER_BY_ID", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
