import state from "./state";

export default {
  SET_ALL_CHANNELS(state, payload) {
    state.channels = payload.result;
  },
  SET_SELECTED_CHANNEL(state, payload) {
    state.selectedChannel = payload;
  },
  ADD_CHANNEL(state, payload) {
    if (state.channels.length) {
      state.channels = [payload, ...state.channels];
    } else {
      state.channels = [payload];
    }
  },
  SET_SELECTED_CHANNEL_MEMBERS(state, payload) {
    state.selectedChannelMembers = payload;
  },
  SET_SELECTED_CHANNEL_JOIN_REQUESTS(state, payload) {
    state.selectedChannelJoinRequest = payload;
  },
  ADD_JOIN_REQUEST(state, payload) {
    const index = state.channels.findIndex((x) => x._id === payload.channelID);
    state.channels[index].isJoined = true;
  },
  REMOVE_JOIN_REQUEST(state, payload) {
    const index = state.selectedChannelJoinRequest.findIndex(
      (x) => x._user.id === payload.userID
    );
    state.selectedChannelJoinRequest.splice(index, 1);
  },
  ADD_MEMBER(state, payload) {
    const index = state.channels.findIndex((x) => x._id === payload.channelID);
    state.channels[index].isMember = true;
  },
  search(state, payload) {
    state.search = payload;
  },
  SET_SELECTE_CHANNEL(state, payload) {
    state.select_channel = payload;
  },
  // DELETE_CHANNLE(state, payload) {
  //   let index = state.channel.findIndex((x) => x._id === payload._id);
  //   state.channel.splice(index, 1);
  // },
  GET_MEMBER_BY_ID(state, payload) {
    state.getMemberById = payload;
  },
};
