import RepositoryFactory from "@/apis";
const eventbooking = RepositoryFactory.eventbooking;

export default {
  BookEvent({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await eventbooking.BookEvent(payload);
        // commit("", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  // GetSingleEvent({ commit }, payload) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await event.GetSingleEvent(payload);

  //       // commit("GET_SINGLE_PAGE_CATEGORY", data.data);

  //       resolve(data);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  userchecked({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await eventbooking.userchecked(payload);

        // commit("GET_ALL_EVENT", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
