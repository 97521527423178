/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

import moduleAuth from "./auth";
import modulehome from "./home";
import modulePosts from "./posts";
import modulecategory from "./category";
import modulesubcategory from "./subcategory";
import moduleproduct from "./product";
import moduleproducthome from "./producthome";
import modulepage from "./page";
import modulepagecategory from "./pagecategory";
import moduleChannel from "./channel";
import homeseomodule from "./homeSeo";
import UI from "./UI";
import ptag from "./ptag";
import email from "./email";
import event from "./event";
import eventbooking from "./eventbooking";
import feedback from "./feedback";
import productsubcategory from "./productsubcategory";
import investment from "./investment";
import Transaction from "./Transaction";
import orders from "./orders";
import Banner from "./Banner";
import Testimonial from "./testimonial";
import PressRelease from "./pressRelease";

export default new Vuex.Store({
  modules: {
    producthome: moduleproducthome,
    auth: moduleAuth,
    home: modulehome,
    channel: moduleChannel,
    posts: modulePosts,
    category: modulecategory,
    subcategory: modulesubcategory,
    product: moduleproduct,
    page: modulepage,
    pagecategoy: modulepagecategory,
    homeseo: homeseomodule,
    UI: UI,
    ptag: ptag,
    email: email,
    event: event,
    eventbooking: eventbooking,
    feedback: feedback,
    productsubcategory: productsubcategory,
    investment: investment,
    Transaction: Transaction,
    orders: orders,
    Banner,
    Testimonial,
    PressRelease,
  },
  strict: process.env.NODE_ENV !== "production",
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});

// window.ls = ls;

// export { ls };
