import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const CREATE_PRESSRELEASE = "create";
const GET_PRESSRELEASE = "get";
const UPDATE_PRESSRELEASE = "update";
const DELETE_PRESSRELEASE = "delete";
const GET_PRESSRELEASEYEARS = "getYears";

export default {
  CreatePressRelease(payload) {
    return Repository.post(
      transformRoute(CREATE_PRESSRELEASE),
      payload,
      formDataConfig
    );
  },
  GetPressRelease(payload) {
    return Repository.post(transformRoute(GET_PRESSRELEASE), payload);
  },

  UpdatePressRelease(payload) {
    return Repository.post(
      transformRoute(UPDATE_PRESSRELEASE),
      payload,
      formDataConfig
    );
  },
  DeletePressRelease(payload) {
    return Repository.post(transformRoute(DELETE_PRESSRELEASE), payload);
  },
  GetPressReleaseYears(payload) {
    return Repository.post(transformRoute(GET_PRESSRELEASEYEARS), payload);
  },
};

const transformRoute = (route) => {
  return `/pressRelease/${route}`;
};
