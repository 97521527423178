export default {
  total_investment: [],
  startupbyid: {},
  category_details: [],
  order_status: {},
  investments: {},
  userInvestments: {},
  paymentinfo: {},
  product_payment_details: {},
};
