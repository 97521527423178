export default {
  SET_CATEGORY(state, payload) {
    state.category = payload;
  },
  GET_Product_SUBCAT_BY_ID(state, payload) {
    state.getproductsubcatbyid = payload;
  },
  GET_All_Product_SUBCAT_BY_ID(state, payload) {
    state.getallproductsubcatbyid = payload;
  },
  PRODUCT_SUB_CAT_DETAILS(state, payload) {
    state.productsubcatdetail = payload;
  },
  DELETE_Product_SUBCATEGORY(state, payload) {
    const index = state.getproductsubcatbyid.findIndex(
      (page) => page._id === payload._id
    );
    state.getproductsubcatbyid.splice(index, 1);
  },
  GETALL_PRODSUBCATEGORY(state, payload) {
    state.prod_subcategory = payload;
  },
};
