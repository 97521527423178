import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const GET = "getproduct";
const GETPRODUCT = "getproductbysubcatid";
const GETALLPRODUCT = "getallproductbysubcatid";
const GETSINGLEPRODUCT = "getsingleproduct";
const GETPRODUCTBYCATID = "getproductbycategoryid";
const ADDPRODUCT = "insertProduct";
const ADDSINGLEPRODUCT = "addproduct";
const DELETEPRODUCT = "deleteproduct";
const EDITPRODUCT = "updateproduct";
const GETPRODUCTBYTAGID = "getproductbytagid";

export default {
  GetProduct(payload) {
    return Repository.post(transformRoute(GET), payload);
  },
  GetProductBySubcatId(payload) {
    return Repository.post(transformRoute(GETPRODUCT), payload);
  },
  GetAllProductBySubcatId(payload) {
    return Repository.post(transformRoute(GETALLPRODUCT), payload);
  },
  GetSingleProduct(payload) {
    return Repository.post(transformRoute(GETSINGLEPRODUCT), payload);
  },
  GetProductByCategoryId(payload) {
    return Repository.post(transformRoute(GETPRODUCTBYCATID), payload);
  },
  AddProduct(payload) {
    return Repository.post(transformRoute(ADDPRODUCT), payload);
  },
  DeleteProduct(payload) {
    return Repository.post(transformRoute(DELETEPRODUCT), payload);
  },
  GetProductByTagId(payload) {
    return Repository.post(transformRoute(GETPRODUCTBYTAGID), payload);
  },
  AddSingleProduct(payload) {
    return Repository.post(
      transformRoute(ADDSINGLEPRODUCT),
      payload,
      formDataConfig
    );
  },
  UpdateProduct(payload) {
    return Repository.post(
      transformRoute(EDITPRODUCT),
      payload,
      formDataConfig
    );
  },
};

const transformRoute = (route) => {
  return `/product/${route}`;
};
