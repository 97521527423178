import Vue from "vue";
// import ComponentsRoutes from "@/core-components/components-route";
import VueRouter from "vue-router";
import AdminRouter from "@/admin/routes/index.js";
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "main",
      component: () => import("@/main/Main.vue"),
      redirect: "home",
      children: [
        ...AdminRouter,
        {
          path: "home",
          name: "Home",
          component: () => import("@/pages/home/Home.vue"),
        },

        {
          path: "/:name/:id",
          name: "Shop",
          component: () => import("@/pages/shop/Shop.vue"),
        },

        {
          //path: "/:pageName/:productName/:gender/:createddate/:categoryId/:subcategoryId/:userid/:productId/:payment",
          path: "/:pageName/:productName/:gender/:createddate/:categoryId/:subcategoryId/:userid/:productId/:payment",
          // path: "checkout",
          name: "checkout",
          component: () => import("@/pages/checkout/Checkout.vue"),
        },

        {
          path: "/:name/:gender/:createdate/:ptagid/:title/:description/:id",
          name: "Shop_apparel",
          component: () => import("@/pages/shop_apparel/Shop.vue"),
        },

        {
          path: "collection",
          name: "Shops",
          component: () => import("@/pages/shop/Shop.vue"),
        },

        {
          path: "/:catname/:name/:id",
          name: "Product-Page",
          component: () => import("@/pages/productview/ProductPage.vue"),
        },
        {
          path: "/:name/:id",
          name: "D-Product-Page",
          component: () => import("@/pages/productview/ProductPage.vue"),
        },

        {
          path: "community",
          name: "Community",
          component: () => import("@/pages/community/Community.vue"),
        },
        {
          path: "community-channel",
          name: "Community-Channel",
          component: () =>
            import("@/pages/communityChannel/CommunityChannel.vue"),
        },
        {
          path: "blogs",
          name: "Blogs",
          component: () => import("@/pages/blogs/Blogs.vue"),
        },
        {
          // path: "blogs-page",
          // path: "/:blogname/:blogtagname/:createddate/:tagidid/:blog_id",
          path: "/:blogname/:tagidid/:blog_id",
          name: "Blogs-page",
          component: () => import("@/pages/blogstory/BlogPage.vue"),
        },
        {
          path: "hrxhub",
          name: "HRXHub",
          component: () => import("@/pages/hrxHub/HRXHub.vue"),
        },
        // {
        //   path: "collection",
        //   name: "collection",
        //   component: () => import("@/pages/shop/Shop.vue"),
        // },

        {
          path: "/channel-discussion",
          name: "channel-discussion",
          component: () => import("@/pages/discussionPage/Discussion.vue"),
        },
        {
          path: "/:catname/:subcatname/:name/:id",
          name: "product",
          component: () => import("@/pages/product/Product.vue"),
        },
        {
          path: "/:catname/:name/:id",
          name: "D-product",
          component: () => import("@/pages/product/Product.vue"),
        },

        {
          path: "user-profile",
          name: "user-profile",
          component: () => import("@/pages/user-profile/User.vue"),
        },
        {
          path: "myorder",
          name: "myorder",
          component: () => import("@/pages/myorder/Myorder.vue"),
        },

        {
          path: "terms-conditions",
          name: "terms-conditions",
          component: () => import("@/pages/terms-conditions/Terms.vue"),
        },
        {
          path: "terms-of-use",
          name: "terms-of-use",
          component: () => import("@/pages/privacy-policy/Policy.vue"),
        },
        {
          path: "events-details",
          name: "events-details",
          component: () => import("@/pages/events/Events.vue"),
        },
        {
          path: "events",
          name: "events",
          component: () => import("@/pages/events/components/Events2.vue"),
        },
        {
          path: "event-about",
          name: "event-about",
          component: () => import("@/pages/events/components/Event3.vue"),
        },
        {
          path: "invoice",
          name: "invoice",
          component: () => import("@/pages/invoice/Invoice.vue"),
        },
        //
        // {
        //   path: "events",
        //   name: "events",
        //   component: () => import("@/pages/events/Events.vue"),
        // },
        // {
        //   path: "event2",
        //   name: "event2",
        //   component: () => import("@/pages/events/components/Events2.vue"),
        // },
        // {
        //   path: "event3",
        //   name: "event3",
        //   component: () => import("@/pages/events/components/Event3.vue"),
        // },
        {
          path: "event2",
          name: "event2",
          component: () => import("@/pages/events/components/Events2.vue"),
        },
        {
          path: "event3",
          name: "event3",
          component: () => import("@/pages/events/components/Event3.vue"),
        },
        {
          path: "connect",
          name: "connect",
          component: () => import("@/pages/connect/Connect.vue"),
        },
        {
          path: "marathon",
          name: "marathon",
          component: () => import("@/pages/marathon/Marathon.vue"),
        },
        {
          path: "product-collection",
          name: "product-collection",
          component: () => import("@/pages/collection/Collection.vue"),
        },
        {
          path: "payment/verification/auth/token/order/verification/confirmation/cashfree/:order_id/:order_token",
          name: "SuccessPaymentPage",
          component: () =>
            import("@/pages/feedbackPages/SuccessPaymentPage.vue"),
        },
        {
          path: "FailurePaymentPage",
          name: "FailurePaymentPage",
          component: () =>
            import("@/pages/feedbackPages/FailurePaymentPage.vue"),
        },
        {
          path: "eatfit",
          name: "eatfit",
          component: () => import("@/pages/food/Food.vue"),
        },
        {
          path: "hrxcafe",
          name: "hrxcafe",
          component: () => import("@/pages/food1/FoodHrx.vue"),
        },
        {
          path: "press-release",
          name: "press-release",
          component: () => import("@/pages/pressrelease/PressReleasepage.vue"),
        },
        {
          path: "press-release-detail",
          name: "press-release-detail",
          component: () =>
            import("@/pages/pressreleasedetails/PressReleaseDetails.vue"),
        },
      ],
    },
    // {
    //   path: "/channel",
    //   name: "maindark",
    //   component: () => import("@/main/MainDark.vue"),
    //   redirect: "channel-discussion",
    //   children: [],
    // },
  ],
});
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router;
