import RepositoryFactory from "@/apis";
const testimonial = RepositoryFactory.Testimonial;

export default {
  CreateTestimonial({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await testimonial.CreateTestimonial(payload);

        // commit("CREATE_TESTIMONIAL", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  GetTestimonial({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await testimonial.GetTestimonial(payload);

        commit("SET_TESTIMONIAL", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  UpdateTestimonial({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await testimonial.UpdateTestimonial(payload);

        // commit("UPDATE_TESTIMONIAL", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  DeleteTestimonial({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await testimonial.DeleteTestimonial(payload);

        // commit("DELETE_TESTIMONIAL", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
