import state from "./state";

export default {
  SET_CATEGORY(state, payload) {
    state.category = payload;
  },
  SET_ALLCATEGORY(state, payload) {
    state.allCategory = payload;
  },
  CATEGORY_DETAILS(state, payload) {
    state.category_details = payload;
  },
  DELETE_CATEGORY(state, payload) {
    const index = state.category.findIndex((page) => page._id == payload._id);
    state.category.splice(index, 1);
  },
  SET_ACTIVECATEGORYNAME(state, payload) {
    state.activeCategoryName = payload;
  },
};
