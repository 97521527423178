import RepositoryFactory from "@/apis";
const home = RepositoryFactory.home;

export default {
  // AddBanner(payload) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await home.AddBanner(payload);

  //       resolve(data);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  GetBanner({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await home.GetBanner(payload);

        commit("SET_BANNER", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
