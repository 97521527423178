export default {
  selectedPost: "",
  posts: [],
  postComments: {},
  commentReplies: {},
  currentuserpost: [],
  currentuserpostComments: {},
  currentusercommentReplies: {},
  type: "",
  url: "",
  media: null,
  text: "",
  Answers: [],
  selectedChannel: null,
  postingChannels: [],
  currentPage: "",
  previousPage: "",
  savedPost: [],
  viewLikes: [],
  select_post: {},
};
