export default {
  SET_PRESSRELEASE(state, payload) {
    state.pressRelease = payload;
  },
  SET_EDITPRESSRELEASE(state, payload) {
    state.editPressRelease = payload;
  },
  SET_PRESSRELEASESECTION(state, payload) {
    state.pressReleaseSection = payload;
  },
  SET_GETPRESSRELEASEYEARS(state, payload) {
    state.getPressReleaseYears =
      payload.years.length > 0 ? payload.years.map(String) : [];
  },
};
