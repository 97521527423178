import RepositoryFactory from "@/apis";
import { Decrypt } from "@/helpers/CryptoJS";
const auth = RepositoryFactory.auth;

export default {
  signupWithPassword({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.signupWithPassword(payload);

        // Set accessToken

        localStorage.setItem("token", Decrypt(data.data).token);

        // Update user details
        commit("UPDATE_USER_INFO", Decrypt(data.data).user);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  generateOTP({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.generateOTP(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  loginWithPassword({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.loginWithPassword(payload);

        // Set accessToken
        localStorage.setItem("token", data.data.token);

        // Update user details
        commit("UPDATE_USER_INFO", data.data.user);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  VerifyOTP({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.VerifyOTP(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  forgotPassword({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.forgotPassword(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  logout({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.logout(payload);

        await commit("logout", null);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateProfile({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.updateProfile(payload);
        // commit("UPDATE_PROFILE", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getsingleProfile({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.getsingleProfile(payload);
        commit("GETSINGLE_PROFILE", data.data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAllUser({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.getAllUser(payload);
        commit("GET_ALL_USER", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAllUserSearch({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.getAllUserSearch(payload);
        commit("GET_ALL_USER_SEARCH", data.data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addAddress({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await auth.addAddress(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
